export default [{
    id: 1,
    value: "Diagnostics and Laboratory technology",
  },
  {
    id: 2,
    value: "Medical Devices",
  },
  {
    id: 3,
    value: "Pharmaceutical and therapeutic",
  },
  {
    id: 4,
    value: "Immunization, vaccine and biologicals",
  },
  {
    id: 5,
    value: "eHealth, mHealth, Telehealth, Medical software and Imaging",
  },
  {
    id: 6,
    value: "อื่นๆ",
  },
];
