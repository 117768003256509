import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);
// const ifAuthenticatedUser = async (to, from, next) => {
//   if (store.state.token && store.state.user.role == 0) {
//     next();
//   } else {
//     next("home");
//   }
// };

const ifAuthenticatedAdmin = async (to, from, next) => {
  if (store.state.token && store.state.user.role == 50) {
    next();
  } else {
    next("/");
  }
};
const ifAuthenticatedUser = async (to, from, next) => {
  if (store.state.token) {
    next();
  } else {
    next("/");
  }
};

const routes = [{
    path: "*",
    component: () => import("@/views/ErrorScreen")
  },
  {
    path: "/",
    name: "index",
    component: () => import("../components/MainEntrance"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/home"),
  },
  {
    path: "/service",
    name: "service",
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import("../views/service"),
  },
  {
    path: "/service/faculty",
    name: "serviceOfFaculty",
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import("../views/serviceOfFac"),
  },
  {
    path: "/service/innovation",
    name: "innovation",
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import("../views/innovation"),
  },
  {
    path: "/service/ip",
    name: "intellectualProperty",
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import("../views/intellectualProperty"),
  },
  {
    path: "/blog",
    name: "blog",
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import("../views/blog"),
  },
  {
    path: "/blog/detail/:blogId",
    name: "blogDetail",
    component: () => import("../views/blogDetail"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/aboutUs"),
  },
  {
    path: "/network",
    name: "network",
    component: () => import("../views/ourNetwork"),
  },
  {
    path: "/map",
    name: "map",
    component: () => import("../views/map"),
  },
  {
    path: "/setting/myAccount",
    name: "myAccount",
    beforeEnter: ifAuthenticatedUser,
    component: () => import("../views/MyAccount"),
  },
  {
    path: "/setting/settingpassword",
    name: "settingpassword",
    beforeEnter: ifAuthenticatedUser,
    component: () => import("../views/SettingPassword"),
  },
  {
    path: "/forgotpassword/:code",
    name: "forgotpassword",
    component: () => import("../views/ForgotPassword"),
  },
  {
    path: "/verify-email",
    name: "verifyEmail",
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import("../views/VerifyEmail"),
  },
   {
     path: "/verify-email/:code",
     name: "verifyEmailCode",
     component: () => import("../views/VerifyEmail"),
   },
  {
    path: "/management",
    name: "management",
    beforeEnter: ifAuthenticatedAdmin,
    component: () => import("../views/Management"),
  },
  {
    path: "/management/service",
    name: "managementService",
    props: (route) => ({
      routeParams: route.query,
    }),
    beforeEnter: ifAuthenticatedAdmin,
    component: () => import("../views/ManagementService"),
  },
  {
    path: "/manageclinic",
    name: "manageClinic",
    beforeEnter: ifAuthenticatedAdmin,
    component: () => import("../views/ManageClinic"),
  },
  {
    path: "/manageclinic/clinic",
    name: "manageClinicEach",
    props: (route) => ({
      routeParams: route.query,
    }),
    beforeEnter: ifAuthenticatedAdmin,
    component: () => import("../views/ManageClinicDetail"),
  },
  {
    path: "/manageintellectual",
    name: "manageIntellectual",
    beforeEnter: ifAuthenticatedAdmin,
    component: () => import("../views/ManageIntellectual"),
  },
  {
    path: "/manageintellectual/intellectual",
    name: "manageIntellectualEach",
    props: (route) => ({
      routeParams: route.query,
    }),
    beforeEnter: ifAuthenticatedAdmin,
    component: () => import("../views/ManageIpDetail"),
  },
  {
    path: "/manageinnovation",
    name: "manageInnovation",
    beforeEnter: ifAuthenticatedAdmin,
    component: () => import("../views/ManageInnovation"),
  }, {
    path: "/manageinnovation/innovation",
    name: "manageInnovationEach",
    props: (route) => ({
      routeParams: route.query,
    }),
    beforeEnter: ifAuthenticatedAdmin,
    component: () => import("../views/ManageInnovationDetail"),
  },
  {
    path: "/research-innovation",
    name: "researchInnovation",
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import("../views/ResearchandInnovation"),
  }, {
    path: "/invester-business",
    name: "investerBusiness",
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import("../views/InvesterandBusiness"),
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
});

export default router;