<template>
  <div class="ele-group">
    <div class="box-all">
      <div class="group-banner">
        <img src="../assets/image/logo_header.png" alt />
      </div>
      <!-- <div class="fac-banner number1 blinker1">
        <img src="../assets/image/partners/logo_med.png" alt />
      </div>
      <div class="fac-banner number2 blinker2">
        <img src="../assets/image/partners/logo_arch.png" alt />
      </div>
      <div class="fac-banner number3 blinker1">
        <img src="../assets/image/partners/logo_dent.png" alt />
      </div>
      <div class="fac-banner number4 blinker2">
        <img src="../assets/image/partners/logo_pharmacy.png" alt />
      </div>
      <div class="fac-banner number5 blinker1">
        <img src="../assets/image/partners/logo_medtech.png" alt />
      </div>
      <div class="fac-banner number6 blinker2">
        <img src="../assets/image/partners/logo_nursing.png" alt />
      </div>
      <div class="fac-banner number7 blinker1">
        <img src="../assets/image/partners/logo_chiangmairam.png" alt />
      </div>
      <div class="fac-banner number8 blinker2">
        <img src="../assets/image/partners/logo_cmneuro.png" alt />
      </div> -->
    </div>
    <div v-if="underGroup == true">
      <h1 class="sub-group">
       ย่านนวัตกรรมการแพทย์สวนดอก
        <br /><span style="font-size: 25px;">Suandok Medical Innovation District</span>  <br />
        <p class="under-group">ย่านนวัตกรรมการแพทย์สวนดอก เป็นศูนย์รวมการให้บริการทางการแพทย์ การพัฒนาวิจัยและนวัตกรรม และส่งเสริมการลงทุน</p>
      </h1>
    </div>
  </div>
</template>
<style  scoped>
body .content .ele-group .box-all .group-banner {
  max-width: 70%;
  width: 100%;
}
@media (max-width: 1199.98px) {
  body .content .ele-group .box-all .group-banner {
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<script>
export default {
  props: ["underGroup"],
};
</script>