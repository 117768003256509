<template>
  <el-dialog
    :visible.sync="set_modal"
    v-if="set_modal"
    :fullscreen="true"
    center
    :show-close="false"
  >
    <el-dialog
      class="terms-of-service"
      :visible.sync="innerVisible"
      append-to-body
    >
      <p class="text-center highlight">
        เงื่อนไขการให้บริการข้อตกลงในการใช้ Web site <br />ระหว่าง
        <br />ย่านนวัตกรรมการแพทย์สวนดอก และผู้ใช้
      </p>
      <p class="highlight">
        ผู้ใดที่เข้าใช้ Web site ถือว่าได้ยอมรับเงื่อนไขการให้บริการดังต่อไปนี้
      </p>
      <p>
        1. ย่านนวัตกรรมการแพทย์สวนดอก จัดให้มี Web site
        นี้เพื่ออำนวยความสะดวกแก่ผู้ที่สนใจค้นหาข้อมูลบริการ หน่วยงาน
        และนวัตกรรม ข่าวสาร รวมไปถึงข้อมูลพื้นฐานภายในย่านนวัตกรรมการแพทย์สวนดอก
        ข้อมูลที่แสดงไว้เป็นข้อมูลเพื่อความรู้ทั่วไปทางด้านนวัตกรรมทางการแพทย์ภายในย่านนวัตกรรมการแพทย์สวนดอกเท่านั้น
        โดยให้อนุญาตเป็นการทั่วไปในการใช้ข้อมูล การ download และถ่ายสำเนา
        โดยจะต้องเป็นไปตามเงื่อนไขการให้บริการแบบทั่วไปของ Web site ต่อไปนี้
        หรือเงื่อนไขที่เฉพาะเจาะจงเป็นบางกรณี ดังที่ระบุเฉพาะในส่วนอื่น
      </p>
      <p>
        2. บทวิเคราะห์ การตีความและข้อสรุป
        เป็นข้อมูลที่ได้มาจากหลายแหล่งภายใต้หน่วยงานต่างๆที่น่าเชื่อถือ
        ทั้งภาครัฐ และเอกชน หรือเป็นความเห็นส่วนตัวของผู้เขียน
        และอาจไม่ใช่ความเห็นของ ย่านนวัตกรรมการแพทย์สวนดอก เว้นแต่จะได้แจ้งไว้
      </p>
      <p>
        3. Web site ย่านนวัตกรรมการแพทย์สวนดอกอาจมีการบันทึกข้อมูลของผู้ใช้
        ซึ่งสมัครสมาชิกเข้ามาในระบบ อันได้แก่ ชื่อ – นามสกุล และ E-mail ติดต่อ
        ทั้งนี้เพื่อประโยชน์สูงสุดในการพัฒนา
        และเป็นไปเพื่ออำนวยความสะดวกในการเข้าถึงเข้ามูลภายใน Web site
        ของย่านนวัตกรรมการแพทย์
      </p>
      <p class="highlight">ข้อยกเว้น</p>
      <p>
        3. ข้อมูลที่แสดงไว้นี้ จัดทำขึ้นโดยนำมาจากแหล่งต่างๆที่เชื่อถือได้
        แต่ย่านนวัตกรรมการแพทย์สวนดอก
        มิอาจรับรองความสมบูรณ์เพื่อการใช้ในทางการค้าหรือประโยชน์อื่นใด
        และอาจมีการเปลี่ยนแปลงปรับปรุงข้อมูลเมื่อใดก็ได้
        โดยไม่ต้องแจ้งให้ทราบล่วงหน้า <br />
        ผู้ใช้ที่ต้องการคำยืนยันเกี่ยวกับความถูกต้อง
        และความสมบูรณ์จะต้องติดต่อเป็นทางการกับหน่วยงานที่เกี่ยวข้องโดยตรง
        ย่านนวัตกรรมการแพทย์สวนดอก จะไม่รับผิดชอบในความเสียหาย สูญเสีย
        ไม่ว่าทางตรงหรือทางอ้อม หรือค่าใช้จ่ายใด ๆ ที่เกิดจากการใช้ Web site นี้
        ไม่ว่าจะเป็นเพราะความไม่สมบูรณ์ ความผิดพลาด ความล่าช้าของข้อมูล
        การขาดความต่อเนื่องของการเชื่อมโยงอุปกรณ์ หรือ ความประมาทเลินเล่อใดๆ
        ไม่ว่าจะมีผู้ใดได้แจ้งหรือแนะนำ
        ย่านนวัตกรรมการแพทย์สวนดอกก่อนหน้าเกี่ยวกับโอกาสที่จะเกิดปัญหาดังกล่าวหรือไม่
      </p>
      <p>
        4. ผู้ใช้ยอมรับและตระหนักดีว่าย่านนวัตกรรมการแพทย์สวนดอก
        จะไม่ต้องรับผิดชอบต่อการกระทำใดๆของผู้ใช้ทั้งสิ้น
      </p>
      <p>
        5. ข้อมูลใน Web site อาจจะมีการแสดงความเห็น ข้อแนะนำ
        หรือบทความจากหลายแหล่ง กรณีเช่นนี้
        ผู้ใช้จะพึงพิจารณาข้อมูลต่างๆด้วยวิจารณญาณของตนเอง ดังนั้น
        ย่านนวัตกรรมการแพทย์สวนดอกไม่ต้องรับผิดต่อผู้ใช้หรือบุคคลใด ๆ
        ในความเสียหายใด ๆ จากการใช้ข้อมูลไม่ว่ากรณีใดๆ
        รวมทั้งกรณีที่ความเสียหายดังกล่าวจะเกิดจากความไม่สมบูรณ์ ความไม่ถูกต้อง
        ความผิดพลาด ความทันสมัย ความสมบูรณ์ของข้อมูล
        การขาดความต่อเนื่องในการเชื่อมโยงอุปกรณ์ ปัญหาที่เกิดขึ้นจาก Computer
        Virus หรือการเปลี่ยนแปลงใดๆในข้อมูลด้วย
      </p>
      <p>
        6. กรณีที่เกิดความเสียหายแก่ ย่านนวัตกรรมการแพทย์สวนดอก
        อันเนื่องมาจากการดำเนินคดี การบังคับใช้สิทธิเรียกร้อง การชดเชยค่าเสียหาย
        ความรับผิดใด ๆ หรือค่าใช้จ่ายใด ๆ (รวมถึงค่าทนาย)
        ที่เกิดสืบเนื่องจากการใช้ Web site ของผู้ใช้
        ผู้ใช้รับจะชดใช้ความเสียหายดังกล่าวให้แก่ ย่านนวัตกรรมการแพทย์สวนดอก
        ทั้งสิ้น
        ไม่ว่ากรณีดังกล่าวจะเกิดจากการที่ผู้ใช้บริการไม่ปฏิบัติตามเงื่อนไขและข้อบังคับของข้อตกลงนี้หรือไม่
        ทั้งนี้หากผู้ใช้ไม่พอใจในข้อมูลที่แสดงหรือไม่พอใจในเงื่อนไขที่ระบุไว้นี้ข้อใด
        ผู้ใช้จะไม่มีสิทธิเรียกร้องใด เว้นแต่ผู้ใช้สามารถระงับการใช้ข้อมูลจาก
        Web site นี้แต่โดยฝ่ายเดียว
      </p>
      <p class="highlight">การเชื่อมโยงกับ Web site ของบุคคลที่สาม</p>
      <p>
        8. Web site นี้มีการเชื่อมโยงกับ Web site ของบุคคลที่สาม ทั้งนี้
        เป็นเพียงการให้บริการเพื่อความสะดวกเท่านั้น ดังนั้น
        ย่านนวัตกรรมการแพทย์สวนดอก จึงไม่มีอำนาจควบคุม รับรอง ยืนยันความถูกต้อง
        หรือรับผิดชอบในเนื้อหาข้อมูลของ Web site ของบุคคลที่สาม นอกจากนี้
        ข้อมูลดังกล่าวอาจมีข้อจำกัดในการที่ผู้ใช้จะนำไปเผยแพร่อีกต่อหนึ่งตามกฎหมายลิขสิทธิ์
        ดังนั้นผู้ใช้จะต้องติดต่อขออนุญาตใช้ลิขสิทธิ์ในข้อมูลดังกล่าวจากเจ้าของข้อมูลโดยตรง
      </p>
      <p>กฎหมายที่บังคับใช้</p>
      <p>
        9. การตีความ และการบังคับให้เป็นไปตามเงื่อนไขการให้บริการฉบับนี้
        ให้เป็นไปตามกฎหมายไทย
      </p>
      <p class="highlight">บททั่วไป</p>
      <p>
        10. ย่านนวัตกรรมการแพทย์สวนดอก
        สามารถระงับหรือจำกัดขอบเขตการเผยแพร่ข้อมูลต่าง ๆหรือปรับปรุงข้อมูลต่างๆ
        เมื่อใดก็ได้ โดยไม่ต้องคำนึงถึงความจำเป็นในการใช้ข้อมูลของผู้ใช้ใด ๆ
        ทั้งสิ้น
      </p>
      <p>
        11. ย่านนวัตกรรมการแพทย์สวนดอก สามารถปฏิเสธสิทธิในการใช้ Web site
        ทั้งหมดหรือบางส่วนแก่ผู้ใดก็ได้ โดยไม่ต้องแจ้งล่วงหน้าด้วย
      </p>
      <p>
        12. ย่านนวัตกรรมการแพทย์สวนดอก
        จะไม่ยกเว้นเงื่อนไขการให้บริการนี้ไม่ว่าส่วนใดให้แก่ผู้ใดทั้งสิ้น
        เว้นแต่จะทำเป็นลายลักษณ์อักษรและลงนามโดยผู้มีอำนาจ
      </p>
    </el-dialog>
    <span slot="title">
      <button class="btn btn-close" @click="closeModal()">
        <img src="../assets/image/close.png" alt />
      </button>
    </span>
    <div class="custom-col-flex">
      <div class="content change-group-left">
        <div class="logo-modal-login">
          <img src="../assets/image/logo_footer.png" alt />
        </div>
        <groupSuandok :underGroup="showUnderGroup" />
      </div>
      <div v-if="tab_login == true" class="box-show-all">
        <div class="box-inside-fix">
          <div class="detail">
            <div class="box-text-head">
              <p class="name-head">SMID ยินดีต้อนรับ</p>
              <span class="name-head dot-red">เข้าสู่ระบบ</span>
            </div>
            <div class="box-form-group">
              <el-form ref="form" :model="form" :rules="rules">
                <el-form-item prop="email">
                  <el-input
                    placeholder="อีเมล"
                    prefix-icon="el-icon-message"
                    v-model="form.email"
                    @keyup.13.native="login"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  prop="password"
                  style="margin-bottom: 0 !important"
                >
                  <el-input
                    type="password"
                    placeholder="รหัสผ่าน"
                    prefix-icon="el-icon-lock"
                    v-model="form.password"
                    show-password
                    @keyup.13.native="login"
                  ></el-input>
                </el-form-item>
                <el-button
                  @click="login"
                  class="btn btn-color-dark"
                  type="primary"
                  round
                  >เข้าสู่ระบบ</el-button
                >
                <p class="text-active" @click="openTabForget()">ลืมรหัสผ่าน?</p>
                <p>
                  ยังไม่ได้เป็นสมาชิก?
                  <span class="text-active" @click="openTabSignUp()"
                    >คลิกเพื่อลงทะเบียน</span
                  >
                </p>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tab_regis == true" class="box-show-all">
        <div class="box-inside-fix">
          <div class="detail">
            <div class="box-text-head">
              <span class="name-head dot-red">ลงทะเบียนสมาชิก</span>
              <p class="text-left">กรุณาเลือกประเภทผู้ใช้งานของคุณ</p>
            </div>
            <div class="box-form-group">
              <el-form ref="formRegis" :model="formRegis" :rules="rules">
                <div class="group-radio">
                  <el-radio-group v-model="formRegis.role">
                    <div class="space-radio">
                      <el-radio-button label="0">
                        <img
                          src="../assets/image/icon/user-white.svg"
                          class="icon-regis"
                          alt
                        />
                        <h1>ผู้ใช้งานทั่วไป</h1>
                      </el-radio-button>
                      <el-radio-button label="1">
                        <img
                          src="../assets/image/icon/microscop-white.svg"
                          class="icon-regis"
                          alt
                        />
                        <h1>นักวิจัย</h1>
                      </el-radio-button>
                      <el-radio-button label="2">
                        <img
                          src="../assets/image/icon/handshake-white.svg"
                          class="icon-regis"
                          alt
                        />
                        <h1>นักลงทุน</h1>
                      </el-radio-button>
                    </div>
                  </el-radio-group>
                </div>
                <el-form-item prop="email">
                  <el-input
                    placeholder="อีเมล"
                    prefix-icon="el-icon-message"
                    v-model="formRegis.email"
                    @keyup.13.native="checkEmail"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input
                    type="password"
                    placeholder="รหัสผ่าน"
                    prefix-icon="el-icon-lock"
                    v-model="formRegis.password"
                    show-password
                    @keyup.13.native="checkEmail"
                  ></el-input>
                </el-form-item>

                <div class="text-left">
                  <el-checkbox v-model="checkedService" @change="checkerror()">
                    <p>ยอมรับ</p>
                  </el-checkbox>
                  <span class="text-active" @click="innerVisible = true"
                    >เงื่อนไขการให้บริการ</span
                  >
                </div>

                <el-button class="btn btn-color-dark" @click="checkEmail"
                  >ลงทะเบียน</el-button
                >
                <p class="text-left text-error" v-if="showError">
                  กรุณายอมรับเงื่อนไขการให้บริการและนโยบายความเป็นส่วนตัวก่อนสมัครใช้บริการ
                </p>
                <p>
                  เป็นสมาชิกแล้ว
                  <span class="text-active" @click="openTabLogin()"
                    >เข้าสู่ระบบ</span
                  >
                </p>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tab_forget == true" class="box-show-all">
        <div class="box-inside-fix">
          <div class="detail">
            <div class="box-text-head">
              <span class="name-head dot-red">ลืมรหัสผ่าน</span>
              <p class="nomal-sub">
                กรุณากรอกอีเมลที่ใช้ลงทะเบียน เพื่อเปลี่ยนรหัสผ่าน
              </p>
            </div>
            <div class="box-form-group">
              <el-form ref="formForgot" :model="formForgot" :rules="rules">
                <el-form-item prop="email">
                  <el-input
                    placeholder="อีเมล"
                    prefix-icon="el-icon-message"
                    v-model="formForgot.email"
                    @keyup.13.native="forGot"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="email" style="display: none">
                  <el-input
                    placeholder="อีเมล"
                    prefix-icon="el-icon-message"
                    v-model="formForgot.email"
                    @keyup.13.native="forGot"
                  ></el-input>
                </el-form-item>
                <el-button
                  @click="forGot"
                  :disabled="countClick"
                  class="btn btn-color-dark"
                  >ยืนยัน</el-button
                >
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import groupSuandok from "@/components/groupSuandok";
import { HTTP } from "@/service/axios.js";
export default {
  components: {
    groupSuandok,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else if (value.length < 8) {
        callback(new Error("รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร"));
      } else {
        callback();
      }
    };
    return {
      checkCount: "",
      countClick: false,
      innerVisible: false,
      showUnderGroup: true,
      showError: false,
      form: {
        email: "",
        password: "",
      },
      formRegis: {
        role: "0",
        email: "",
        password: "",
      },
      formForgot: {
        email: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: "กรุณากรอกอีเมล",
            trigger: "blur",
          },
          {
            type: "email",
            message: "กรุณากรอกอีเมลให้ถูกต้อง",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
      },
      checkedService: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    },
    set_modal() {
      return this.$store.state.set_modal;
    },
    tab_login() {
      return this.$store.state.tab_login;
    },
    tab_regis() {
      return this.$store.state.tab_regis;
    },
    tab_forget() {
      return this.$store.state.tab_forget;
    },
  },
  methods: {
    closeModal() {
      this.$store.commit("SET_MODAL", false);
      this.$store.commit("TAB_LOGIN", false);
      this.$store.commit("TAB_REGIS", false);
      this.$store.commit("TAB_FORGET", false);
    },
    openTabForget() {
      this.$store.commit("TAB_REGIS", false);
      this.$store.commit("TAB_LOGIN", false);
      this.$store.commit("TAB_FORGET", true);
    },
    openTabSignUp() {
      this.$refs["form"].resetFields();
      this.$store.commit("TAB_LOGIN", false);
      this.$store.commit("TAB_REGIS", true);
      this.$store.commit("TAB_FORGET", false);
    },
    openTabLogin() {
      this.$refs["formRegis"].resetFields();
      this.$store.commit("TAB_LOGIN", true);
      this.$store.commit("TAB_REGIS", false);
      this.$store.commit("TAB_FORGET", false);
    },
    checkerror() {
      if (this.checkedService == true) {
        this.showError = false;
      }
    },
    getuser() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`user`)
        .then((res) => {
          if (res.data.success) {
            this.$store.commit("SET_USER", res.data.obj);
            if (this.user.role == 50) {
              this.$router.push(`/management`).catch(() => {});
            }
            if (this.user.gender == undefined) {
              this.$router.push(`/setting/myAccount`);
            }
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("getuser error", e);
        });
    },
    login() {
      this.$refs["form"].validate((valid) => {
        console.log(valid);
        if (valid) {
          let obj = {
            email: this.form.email,
            password: this.form.password,
          };

          HTTP.post(`sign/in`, obj)
            .then((res) => {
              console.log(res);
              if (res.data.success) {
                this.$store.commit("SET_USER", res.data.obj);
                this.$store.commit("SET_TOKEN", res.data.obj.token);
                this.$store.commit("SET_MODAL", false);
                this.getuser();
              } else {
                if (res.data.status == 200) {
                  this.alertCatchError("อีเมลหรือรหัสผ่านไม่ถูกต้อง");
                } else {
                  this.closeModal();
                    let obj1 = {
                    email:this.form.email,
                  };
                  HTTP.post(`api/verify`, obj1)
                    .then((res) => {
                      console.log(res);
                      if (res.data.success) {
                        this.$message({
                          message:
                            "ส่งเมลยืนยันตัวตนสำเร็จ กรุณาตรวจสอบอีเมลของท่าน",
                          type: "success",
                          duration: 4000,
                        });
                      } else {
                        this.alertFailError();
                      }
                    })
                    .catch((e) => {
                      this.alertCatchError(e);
                      console.log("Verify error", e);
                    });
                  this.$router.push(`/verify-email?email=${obj.email}`);
                }
              }
            })
            .catch((e) => {
              this.alertCatchError(e);
              console.log("login error", e);
            });
        }
      });
    },
    forGot() {
      this.countClick = true;
      this.$refs["formForgot"].validate((valid) => {
        if (valid) {
          let obj = {
            email: this.formForgot.email,
          };

          HTTP.post(`forgot/password`, obj)
            .then((res) => {
              console.log(res);
              if (res.data.success) {
                // this.$store.commit("SET_USER", res.data.obj);
                this.$message({
                  message: "ส่งอีเมลลืมรหัสผ่านสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$store.commit("SET_MODAL", false);
                this.$store.commit("TAB_FORGET", false);
                this.$refs["formForgot"].resetFields();
              } else {
                this.alertCatchError(res.data.data);
              }
            })
            .catch((e) => {
              this.alertCatchError(e);
              console.log("formForgot error", e);
            })
            .finally(() => {
              this.countClick = false;
            });
        } else {
          this.countClick = false;
        }
      });
    },
    signUp() {
      this.$refs["formRegis"].validate((valid) => {
        if (valid) {
          let obj = {
            email: this.formRegis.email,
            password: this.formRegis.password,
            role: this.formRegis.role,
          };

          if (this.checkedService == true) {
            HTTP.post(`sign/up`, obj)
              .then((res) => {
                if (res.data.success) {
                  // HTTP.post(`sign/in`, obj)
                  //   .then((res) => {
                  //     if (res.data.success) {
                  //       this.$message({
                  //         message: "สมัครสมาชิกสำเร็จ",
                  //         type: "success",
                  //         duration: 4000,
                  //       });
                  //       this.$store.commit("SET_USER", res.data.obj);
                  //       this.$store.commit("SET_MODAL", false);
                  //       this.$store.commit("SET_TOKEN", res.data.obj.token);
                  //       this.$router.push(`/setting/myAccount`);
                  //     } else {
                  //       this.alertFailError();
                  //     }
                  //   })
                  //   .catch((e) => {
                  //     this.alertCatchError(e);
                  //     console.log("login error", e);
                  //   });
                  this.closeModal();
                  let obj1 = {
                    email:this.formRegis.email,
                  };
                  HTTP.post(`api/verify`, obj1)
                    .then((res) => {
                      console.log(res);
                      if (res.data.success) {
                        this.$message({
                          message:
                            "ส่งเมลยืนยันตัวตนสำเร็จ กรุณาตรวจสอบอีเมลของท่าน",
                          type: "success",
                          duration: 4000,
                        });
                      } else {
                        this.alertFailError();
                      }
                    })
                    .catch((e) => {
                      this.alertCatchError(e);
                      console.log("Verify error", e);
                    });
                  this.$router.push(`/verify-email?email=${obj.email}`);
                } else {
                  this.alertCatchError("สมัครสมาชิกไม่สำเร็จ");
                }
              })
              .catch((e) => {
                this.alertCatchError(e);
                console.log("สมัครสมาชิกไม่สำเร็จ", e);
              });
          } else {
            this.showError = true;
          }
        }
      });
    },
    checkEmail() {
      this.$refs["formRegis"].validate((valid) => {
        if (valid) {
          let obj = {
            email: this.formRegis.email,
          };
          console.log(obj);
          HTTP.post(`check/email`, obj)
            .then((res) => {
              console.log(res);
              if (res.data.success) {
                this.signUp();
              } else {
                this.alertCatchError("อีเมล์นี้ถูกใช้งานแล้ว");
              }
            })
            .catch((e) => {
              this.alertCatchError(e);
              console.log("อีเมล์นี้ถูกใช้งานแล้ว", e);
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scope>
.el-form-item {
  margin-bottom: 15px !important;
}
.terms-of-service {
  .el-dialog {
    width: 100%;
    margin-top: 5vh !important;
    max-width: 70%;
    @media (max-width: 991.98px) {
      max-width: 90%;
    }
    p {
    word-break: break-word;
      font-size: 16px;
      margin-top: 0;
    }
    .highlight {
      font-weight: 600;
    }
  }
}
</style>