<template>
  <div id="footer">
    <div class="container">
      <el-row :gutter="24" class="group-list-footer">
        <el-col :span="24" :md="6">
          <div class="grid-content text-center">
            <a href="/home">
              <img
                src="../assets/image/logo_footer.png"
                class="logo-footer"
                alt
              />
            </a>
          </div>
        </el-col>
        <el-col :span="24" :md="6">
          <div class="grid-content detail-footer">
            <div class="title-footer">Our Service</div>
            <ul class="sub-footer">
              <li>
                <router-link :to="{ name: 'network' }"
                  >ศูนย์บริการทั้งหมด</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'service' }"
                  >บริการทางการแพทย์</router-link
                >
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :span="24" :md="6">
          <div class="grid-content detail-footer">
            <div class="title-footer">About</div>
            <ul class="sub-footer">
              <li>
                <router-link :to="{ name: 'about' }">About Us</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'network' }">Our Network</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'map' }">Map</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'blog' }">Blog</router-link>
              </li>
            </ul>
          </div>
        </el-col>
        <el-col :span="24" :md="6">
          <div class="grid-content detail-footer">
            <div class="title-footer">Get In Touch</div>
            <ul class="sub-footer">
              <li>ย่านนวัตกรรมการแพทย์สวนดอก</li>
              <li>
                <a href="tel:+66960619394">โทร. 096-0619394</a>
              </li>
              <li>
                <a href="mailto:smid.info1@gmail.com">smid.info1@gmail.com</a>
              </li>
            </ul>
            <div class="box-social">
              <button class="btn btn-social">
                <a
                  href="https://www.facebook.com/SMIDofficial/"
                  target="_blank"
                >
                  <img src="../assets/image/icon/facebook.svg" alt />
                </a>
              </button>
              <!-- <button class="btn btn-social">
                <a href>
                  <img src="../assets/image/icon/line.svg" alt />
                </a>
              </button> -->
              <!-- <button class="btn btn-social">
                <a href>
                  <img src="../assets/image/icon/youtube.svg" alt />
                </a>
              </button> -->
              <button class="btn btn-social">
                <a href="http://m.me/SMIDofficial/" target="_blank">
                  <img src="../assets/image/icon/messenge.svg" alt />
                </a>
              </button>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="box-copyright">
        <span>Copyright © SMID 2020. All Rights Reserved.</span>
        <div class="tab-r">
          <!-- <span>
            <a>Sitemap</a>
          </span> -->
          <span style="cursor: pointer" @click="innerVisible = true">
            <a>Terms & Conditions</a>
          </span>
          <span @click="innerVisible = true" style="cursor: pointer">
            <a>Privacy Policy</a>
          </span>
        </div>
      </div>
    </div>
    <el-dialog
      class="terms-of-service"
      :visible.sync="innerVisible"
      append-to-body
    >
      <p class="text-center highlight">
        เงื่อนไขการให้บริการข้อตกลงในการใช้ Web site <br />ระหว่าง
        <br />ย่านนวัตกรรมการแพทย์สวนดอก และผู้ใช้
      </p>
      <p class="highlight">
        ผู้ใดที่เข้าใช้ Web site ถือว่าได้ยอมรับเงื่อนไขการให้บริการดังต่อไปนี้
      </p>
      <p>
        1. ย่านนวัตกรรมการแพทย์สวนดอก จัดให้มี Web site
        นี้เพื่ออำนวยความสะดวกแก่ผู้ที่สนใจค้นหาข้อมูลบริการ หน่วยงาน
        และนวัตกรรม ข่าวสาร รวมไปถึงข้อมูลพื้นฐานภายในย่านนวัตกรรมการแพทย์สวนดอก
        ข้อมูลที่แสดงไว้เป็นข้อมูลเพื่อความรู้ทั่วไปทางด้านนวัตกรรมทางการแพทย์ภายในย่านนวัตกรรมการแพทย์สวนดอกเท่านั้น
        โดยให้อนุญาตเป็นการทั่วไปในการใช้ข้อมูล การ download และถ่ายสำเนา
        โดยจะต้องเป็นไปตามเงื่อนไขการให้บริการแบบทั่วไปของ Web site ต่อไปนี้
        หรือเงื่อนไขที่เฉพาะเจาะจงเป็นบางกรณี ดังที่ระบุเฉพาะในส่วนอื่น
      </p>
      <p>
        2. บทวิเคราะห์ การตีความและข้อสรุป
        เป็นข้อมูลที่ได้มาจากหลายแหล่งภายใต้หน่วยงานต่างๆที่น่าเชื่อถือ
        ทั้งภาครัฐ และเอกชน หรือเป็นความเห็นส่วนตัวของผู้เขียน
        และอาจไม่ใช่ความเห็นของ ย่านนวัตกรรมการแพทย์สวนดอก เว้นแต่จะได้แจ้งไว้
      </p>
      <p>
        3. Web site ย่านนวัตกรรมการแพทย์สวนดอกอาจมีการบันทึกข้อมูลของผู้ใช้
        ซึ่งสมัครสมาชิกเข้ามาในระบบ อันได้แก่ ชื่อ – นามสกุล และ E-mail ติดต่อ
        ทั้งนี้เพื่อประโยชน์สูงสุดในการพัฒนา
        และเป็นไปเพื่ออำนวยความสะดวกในการเข้าถึงเข้ามูลภายใน Web site
        ของย่านนวัตกรรมการแพทย์
      </p>
      <p class="highlight">ข้อยกเว้น</p>
      <p>
        3. ข้อมูลที่แสดงไว้นี้ จัดทำขึ้นโดยนำมาจากแหล่งต่างๆที่เชื่อถือได้
        แต่ย่านนวัตกรรมการแพทย์สวนดอก
        มิอาจรับรองความสมบูรณ์เพื่อการใช้ในทางการค้าหรือประโยชน์อื่นใด
        และอาจมีการเปลี่ยนแปลงปรับปรุงข้อมูลเมื่อใดก็ได้
        โดยไม่ต้องแจ้งให้ทราบล่วงหน้า <br />
        ผู้ใช้ที่ต้องการคำยืนยันเกี่ยวกับความถูกต้อง
        และความสมบูรณ์จะต้องติดต่อเป็นทางการกับหน่วยงานที่เกี่ยวข้องโดยตรง
        ย่านนวัตกรรมการแพทย์สวนดอก จะไม่รับผิดชอบในความเสียหาย สูญเสีย
        ไม่ว่าทางตรงหรือทางอ้อม หรือค่าใช้จ่ายใด ๆ ที่เกิดจากการใช้ Web site นี้
        ไม่ว่าจะเป็นเพราะความไม่สมบูรณ์ ความผิดพลาด ความล่าช้าของข้อมูล
        การขาดความต่อเนื่องของการเชื่อมโยงอุปกรณ์ หรือ ความประมาทเลินเล่อใดๆ
        ไม่ว่าจะมีผู้ใดได้แจ้งหรือแนะนำ
        ย่านนวัตกรรมการแพทย์สวนดอกก่อนหน้าเกี่ยวกับโอกาสที่จะเกิดปัญหาดังกล่าวหรือไม่
      </p>
      <p>
        4. ผู้ใช้ยอมรับและตระหนักดีว่าย่านนวัตกรรมการแพทย์สวนดอก
        จะไม่ต้องรับผิดชอบต่อการกระทำใดๆของผู้ใช้ทั้งสิ้น
      </p>
      <p>
        5. ข้อมูลใน Web site อาจจะมีการแสดงความเห็น ข้อแนะนำ
        หรือบทความจากหลายแหล่ง กรณีเช่นนี้
        ผู้ใช้จะพึงพิจารณาข้อมูลต่างๆด้วยวิจารณญาณของตนเอง ดังนั้น
        ย่านนวัตกรรมการแพทย์สวนดอกไม่ต้องรับผิดต่อผู้ใช้หรือบุคคลใด ๆ
        ในความเสียหายใด ๆ จากการใช้ข้อมูลไม่ว่ากรณีใดๆ
        รวมทั้งกรณีที่ความเสียหายดังกล่าวจะเกิดจากความไม่สมบูรณ์ ความไม่ถูกต้อง
        ความผิดพลาด ความทันสมัย ความสมบูรณ์ของข้อมูล
        การขาดความต่อเนื่องในการเชื่อมโยงอุปกรณ์ ปัญหาที่เกิดขึ้นจาก Computer
        Virus หรือการเปลี่ยนแปลงใดๆในข้อมูลด้วย
      </p>
      <p>
        6. กรณีที่เกิดความเสียหายแก่ ย่านนวัตกรรมการแพทย์สวนดอก
        อันเนื่องมาจากการดำเนินคดี การบังคับใช้สิทธิเรียกร้อง การชดเชยค่าเสียหาย
        ความรับผิดใด ๆ หรือค่าใช้จ่ายใด ๆ (รวมถึงค่าทนาย)
        ที่เกิดสืบเนื่องจากการใช้ Web site ของผู้ใช้
        ผู้ใช้รับจะชดใช้ความเสียหายดังกล่าวให้แก่ ย่านนวัตกรรมการแพทย์สวนดอก
        ทั้งสิ้น
        ไม่ว่ากรณีดังกล่าวจะเกิดจากการที่ผู้ใช้บริการไม่ปฏิบัติตามเงื่อนไขและข้อบังคับของข้อตกลงนี้หรือไม่
        ทั้งนี้หากผู้ใช้ไม่พอใจในข้อมูลที่แสดงหรือไม่พอใจในเงื่อนไขที่ระบุไว้นี้ข้อใด
        ผู้ใช้จะไม่มีสิทธิเรียกร้องใด เว้นแต่ผู้ใช้สามารถระงับการใช้ข้อมูลจาก
        Web site นี้แต่โดยฝ่ายเดียว
      </p>
      <p class="highlight">การเชื่อมโยงกับ Web site ของบุคคลที่สาม</p>
      <p>
        8. Web site นี้มีการเชื่อมโยงกับ Web site ของบุคคลที่สาม ทั้งนี้
        เป็นเพียงการให้บริการเพื่อความสะดวกเท่านั้น ดังนั้น
        ย่านนวัตกรรมการแพทย์สวนดอก จึงไม่มีอำนาจควบคุม รับรอง ยืนยันความถูกต้อง
        หรือรับผิดชอบในเนื้อหาข้อมูลของ Web site ของบุคคลที่สาม นอกจากนี้
        ข้อมูลดังกล่าวอาจมีข้อจำกัดในการที่ผู้ใช้จะนำไปเผยแพร่อีกต่อหนึ่งตามกฎหมายลิขสิทธิ์
        ดังนั้นผู้ใช้จะต้องติดต่อขออนุญาตใช้ลิขสิทธิ์ในข้อมูลดังกล่าวจากเจ้าของข้อมูลโดยตรง
      </p>
      <p>กฎหมายที่บังคับใช้</p>
      <p>
        9. การตีความ และการบังคับให้เป็นไปตามเงื่อนไขการให้บริการฉบับนี้
        ให้เป็นไปตามกฎหมายไทย
      </p>
      <p class="highlight">บททั่วไป</p>
      <p>
        10. ย่านนวัตกรรมการแพทย์สวนดอก
        สามารถระงับหรือจำกัดขอบเขตการเผยแพร่ข้อมูลต่าง ๆหรือปรับปรุงข้อมูลต่างๆ
        เมื่อใดก็ได้ โดยไม่ต้องคำนึงถึงความจำเป็นในการใช้ข้อมูลของผู้ใช้ใด ๆ
        ทั้งสิ้น
      </p>
      <p>
        11. ย่านนวัตกรรมการแพทย์สวนดอก สามารถปฏิเสธสิทธิในการใช้ Web site
        ทั้งหมดหรือบางส่วนแก่ผู้ใดก็ได้ โดยไม่ต้องแจ้งล่วงหน้าด้วย
      </p>
      <p>
        12. ย่านนวัตกรรมการแพทย์สวนดอก
        จะไม่ยกเว้นเงื่อนไขการให้บริการนี้ไม่ว่าส่วนใดให้แก่ผู้ใดทั้งสิ้น
        เว้นแต่จะทำเป็นลายลักษณ์อักษรและลงนามโดยผู้มีอำนาจ
      </p>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      innerVisible: false,
    };
  },
};
</script>
