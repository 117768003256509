import symptomList from "./constant/symptomList";
import innovationList from "./constant/innovationList";
const initState = () => ({
  token: "",
  user: null,
  set_modal: null,
  tab_login: null,
  tab_regis: null,
  tab_forget: null,
  symptomList: symptomList,
  innovationList: innovationList,
});

export default initState;