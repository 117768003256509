<template>
  <div id="nav-admin">
    <div class="nav-header">
      <div class="nav-image" @click="gotoPage('/management')">
        <el-image
          :src="require('@/assets/image/logo_footer.png')"
          class="logo-nav show-in-desktop"
        ></el-image>
        <span class="show-in-mobile bars" @click="drawer = true">
          <i class="fas fa-bars"></i>
        </span>
      </div>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <img
            src="../assets/image/user.png"
            class="profile-user"
            alt
            v-if="user.profileImg == undefined"
          />
          <img
            :src="callImage + user._id + '/' + user.profileImg"
            class="profile-user"
            alt
            v-else
          />
          <span
            class="font-active"
            v-if="user.name == '' || user.name == undefined"
            >{{ user.email }}</span
          >
          <span class="font-active" v-else>{{ user.name }}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="setting-user tab">
          <el-dropdown-item>
            <p style="margin: 0" class="font18">ตั้งค่าบัญชี</p>
            <ul>
              <li>
                <router-link to="/setting/myAccount">บัญชีของฉัน</router-link>
              </li>
              <li>
                <router-link to="/setting/settingpassword"
                  >เปลี่ยนรหัสผ่านใหม่</router-link
                >
              </li>
            </ul>
          </el-dropdown-item>
          <el-dropdown-item>
            <a @click="signOut()" class="font18">ออกจากระบบ</a>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-drawer
      :visible.sync="drawer"
      :direction="direction"
      class="custom-slidebar"
      :size="'65px'"
    >
      <div class="nav-nenu-box">
        <el-menu
          :default-active="navIndex"
          class="el-menu-vertical-demo menu-nav"
          :class="{ 'position-t-0': navTop < 0 }"
          :style="`top:${navTop}px`"
          :collapse="true"
          background-color="#ae81c5 "
          text-color="#ffffff"
          active-text-color="#ffffff"
        >
          <el-menu-item index="1">
            <div @click="gotoPage('/management')">
              <i class="fas fa-calendar-alt"></i>
            </div>
            <span slot="title">จัดการบริการทางการแพทย์</span>
          </el-menu-item>
          <el-menu-item index="2">
            <div @click="gotoPage('/manageclinic')">
              <i class="fas fa-briefcase-medical"></i>
            </div>
            <span slot="title">คลินิก</span>
          </el-menu-item>
          <el-menu-item index="3">
            <div @click="gotoPage('/manageinnovation')">
              <i class="fas fa-atom"></i>
            </div>
            <span slot="title">นวัตกรรม</span>
          </el-menu-item>
          <el-menu-item index="4">
            <div @click="gotoPage('/manageintellectual')">
              <i class="fas fa-award"></i>
            </div>
            <span slot="title">ทรัพย์สินทางปัญญา</span>
          </el-menu-item>
          <el-menu-item index="5">
            <div @click="settingBlog()">
              <i class="far fa-newspaper"></i>
            </div>
            <span slot="title">จัดการ Blog</span>
          </el-menu-item>
        </el-menu>
      </div>
    </el-drawer>
    <div class="flex-display">
      <div class="nav-nenu-box show-in-desktop">
        <el-menu
          :default-active="navIndex"
          class="el-menu-vertical-demo menu-nav"
          :class="{ 'position-t-0': navTop < 0 }"
          :style="`top:${navTop}px`"
          :collapse="true"
          background-color="#ae81c5 "
          text-color="#ffffff"
          active-text-color="#ffffff"
        >
          <el-menu-item index="1" @click="gotoPage('/management')">
            <i class="fas fa-calendar-alt"></i>
            <span slot="title">จัดการบริการทางการแพทย์</span>
          </el-menu-item>
          <el-menu-item index="2" @click="gotoPage('/manageclinic')">
            <i class="fas fa-briefcase-medical"></i>
            <span slot="title">คลินิก</span>
          </el-menu-item>
          <el-menu-item index="3" @click="gotoPage('/manageinnovation')">
            <i class="fas fa-atom"></i>
            <span slot="title">นวัตกรรม</span>
          </el-menu-item>
          <el-menu-item index="4" @click="gotoPage('/manageintellectual')">
            <i class="fas fa-award"></i>
            <span slot="title">ทรัพย์สินทางปัญญา</span>
          </el-menu-item>
          <el-menu-item index="5" @click="settingBlog()">
            <i class="far fa-newspaper"></i>
            <span slot="title">จัดการ Blog</span>
          </el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  created() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      drawer: false,
      direction: "ltr",
      navIndex: "1",
      navTop: 50,
      callImage: process.env.VUE_APP_BASE_URL_IMAGE,
      widthMax: window.innerWidth,
    };
  },
  mounted() {
    this.checkPage();
  },
  methods: {
    //  hideNavAdmin() {
    //   if (this.user.role == 50) {
    //   }
    // },
    checkPage() {
      var loc = window.location.pathname;
      var dir = loc.replace("/", "");
      console.log(dir);
      if (dir == "management") {
        this.navIndex = "1";
      }
      if (dir == "manageclinic" || dir == "manageclinic/clinic") {
        this.navIndex = "2";
      }
      if (dir == "manageinnovation" || dir == "manageinnovation/innovation") {
        this.navIndex = "3";
      }
      if (
        dir == "manageintellectual" ||
        dir == "manageintellectual/intellectual"
      ) {
        this.navIndex = "4";
      }
      if (dir == "") {
        document.getElementById("nav-admin").style.display = "none";
      } else {
        document.getElementById("nav-admin").style.display = "block";
      }
    },
    gotoPage(path) {
      this.$router.push(path).catch(() => {});
      this.checkPage();
    },
    settingBlog() {
      window.open("https://blog.smid.lffintech.co.th/ghost/", "_blank");
    },
    signOut() {
      this.$store.commit("SIGNOUT");
      this.$router.push(`/home`);
    },
    handleScroll() {
      var elmnt = document.getElementsByTagName("html");
      this.navTop = 50 - elmnt[0].scrollTop;
    },
  },
};
</script>