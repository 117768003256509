console.log = function () {};
import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import ElementUI from "element-ui";
import VueCarousel from "vue-carousel";
import locale from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/style/custom.scss";
import "@/service/alert";
import store from "./store";
import moment from "moment";
import * as VueGoogleMaps from "vue2-google-maps";
import momentTH from 'moment/src/locale/th'
import VueMask from 'v-mask'
import VueMeta from 'vue-meta'
import VueSocialSharing from 'vue-social-sharing'
 
Vue.use(VueSocialSharing);
Vue.use(VueMeta)
Vue.use(VueMask);
Vue.use(ElementUI, {
  locale
});
Vue.use(VueRouter);
Vue.use(moment);
Vue.use(VueCarousel);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD2g6CXdO0BQE63OmxAdYAzAkTCx0a42UM", // giztix
    libraries: "places,directions",
  },
});
Vue.config.productionTip = false;

Vue.filter('timeTh', (date) => {
  let res = '';
  if (typeof date === 'undefined' || date === null || date === '') {
    res = '-';
  } else {
    res = moment(moment(date).locale('th', momentTH).add(543, 'y')).format('D MMM YYYY');
  }
  return res;
});
Vue.filter("commaNoDot", (value) => {
  if (
    typeof value === "undefined" ||
    value === null ||
    value === "" ||
    value === 0
  ) {
    return 0;
  } else {
    value = value.toFixed(0);
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
});

Vue.mixin({
  methods: {
     indexMethod(index, page, pageSize) {
      return index + 1 + (page - 1) * pageSize;
    },
    getGhostImageSet(url, sizes) {
      if (url != null) {
        const urlJoin = []
        for (let i = 0; i < sizes.length; i++) {
          const replace = 'content/images'
          const urlSplit = url.replace(replace, replace + '/size/w' + sizes[i])
          urlJoin.push(urlSplit + ' ' + sizes[i] + 'w')
        }
        return urlJoin.join(',')
      } else {
        return null
      }
    },
    getGhostImage(url, size) {
      if (url != null) {
        const replace = 'content/images'
        const urlSplit = url.replace(replace, replace + '/size/w' + size)
        return urlSplit
      } else {
        return null
      }
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
