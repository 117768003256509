import initState from "./initState";

export default {
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_MODAL(state, payload) {
    state.set_modal = payload;
  },
  TAB_LOGIN(state, payload) {
    state.tab_login = payload;
  },
  TAB_REGIS(state, payload) {
    state.tab_regis = payload;
  },
  TAB_FORGET(state, payload) {
    state.tab_forget = payload;
  }, 
  SIGNOUT: (state) => {
    const initial = initState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
};