export default [
  {
    id: 1,
    value: "อายุรศาสตร์/โรคทั่วไป",
  },
  {
    id: 2,
    value: "เวชศาสตร์ฉุกเฉิน",
  },
  {
    id: 3,
    value: "ทันตกรรม",
  },
  {
    id: 4,
    value: "จักษุวิทยา",
  },
  {
    id: 5,
    value: "โสต คอ นาสิกวิทยา",
  },
  {
    id: 27,
    value: "สูติศาสตร์ นารีเวชวิทยา",
  },
  {
    id: 6,
    value: "กุมารเวช-ศาสตร์",
  },
  {
    id: 7,
    value: "จิตเวชศาสตร์",
  },
  {
    id: 8,
    value: "รังสีวิทยา",
  },
  {
    id: 9,
    value: "ศัลยศาสตร์",
  },
  {
    id: 10,
    value: "ระบบทางเดินหายใจ",
  },
  {
    id: 11,
    value: "ระบบทางเดินอาหาร",
  },
  {
    id: 12,
    value: "โรคระบบขับถ่าย",
  },
  {
    id: 13,
    value: "ระบบประสาท",
  },
  {
    id: 14,
    value: "กระดูก ข้อ เส้นเอ็น กล้ามเนื้อ",
  },
  {
    id: 15,
    value: "โรคผิวหนัง",
  },
  {
    id: 16,
    value: "โรคหัวใจ และหลอดเลือด",
  },
  {
    id: 17,
    value: "พยาธิวิทยา",
  },
  {
    id: 18,
    value: "โรคตับ",
  },
  {
    id: 19,
    value: "โรคติดต่อ",
  },
  {
    id: 20,
    value: "โรคติดต่อทางเพศสัมพันธ์",
  },
  {
    id: 21,
    value: "โรคต่อมไร้ท่อ",
  },
  {
    id: 22,
    value: "โรคทางพันธุกรรม",
  },
  {
    id: 23,
    value: "โรคมะเร็ง",
  },
  {
    id: 24,
    value: "เวชศาสตร์ฟื้นฟู",
  },
  {
    id: 25,
    value: "เวชศาสตร์ป้องกัน",
  },
  {
    id: 26,
    value: "วิสัญญีวิทยา",
  },
];
