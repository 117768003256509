import { HTTP } from "@/service/axios";

export default {
  FETCH_INSTRUCTORS_LIST: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`instructors/0/0/all`)
      .then((res) => {
        if (res.data.success) {
          commit("SET_INSTRUCTORS_LIST", res.data.obj);
        }
      })
      .catch((e) => {
        console.log("FETCH_USER_LIST error", e);
      });
  },
  FETCH_COURSE_LIST: async ({ commit }, obj) => {
    HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
    await HTTP.get(`course/0/0/all`)
      .then((res) => {
        if (res.data.success) {
          commit("SET_COURSE_LIST", res.data.obj);
        }
      })
      .catch((e) => {
        console.log("FETCH_COURSE_LIST error", e);
      });
  },
};
