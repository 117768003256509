<template>
  <div id="navbar">
    <div class="hidden-web">
      <div class="nav-mb" id="nav2">
        <div class="box-nav">
          <a href="/home">
            <img
              src="../assets/image/logo_header.png"
              alt
              class="logo-header"
            />
          </a>
          <el-dropdown trigger="click" class="clickbar" :hide-on-click="false">
            <span class="el-dropdown-link">
              <i class="fas fa-bars"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="custom-menu">
              <el-dropdown-item>
                <el-collapse class="collapse-service">
                  <el-collapse-item title="SMID" name="1">
                    <ul class="ul-smid">
                      <li><a href="/service">Service</a></li>
                      <li>
                        <a href="/research-innovation"
                          >Research and Innovation</a
                        >
                      </li>
                      <li>
                        <a href="/invester-business"
                          >Invester and Business development</a
                        >
                      </li>
                    </ul>
                  </el-collapse-item>
                </el-collapse>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="/blog">Blog</a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="/about">About Us</a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="/network">Our NETWORK</a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a href="/map">MAP</a>
              </el-dropdown-item>
              <el-dropdown-item class="text-center" v-if="user == null">
                <button
                  class="btn btn-color-light btn-login"
                  @click="openModalLogin()"
                >
                  <a>Login</a>
                </button>
              </el-dropdown-item>
              <el-dropdown-item class="text-center" v-if="user == null">
                <button
                  class="btn btn-color-dark btn-signup"
                  @click="openModalSignUp()"
                >
                  <a>Sign Up</a>
                </button>
              </el-dropdown-item>
              <el-dropdown-item class="text-center height-user" v-else>
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <img
                      src="../assets/image/user.png"
                      class="profile-user"
                      alt
                      v-if="user.profileImg == undefined"
                    />
                    <img
                      :src="callImage + user._id + '/' + user.profileImg"
                      class="profile-user"
                      alt
                      v-else
                    />
                    <span
                      class="font-active"
                      v-if="user.name == '' || user.name == undefined"
                      >{{ user.email }}</span
                    >
                    <span class="font-active" v-else>{{ user.name }}</span>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="setting-user tab">
                    <el-dropdown-item>
                      <p style="margin: 0" class="font18">ตั้งค่าบัญชี</p>
                      <ul>
                        <li>
                          <a href="/setting/myAccount">บัญชีของฉัน</a>
                        </li>
                        <li>
                          <a href="/setting/settingpassword"
                            >เปลี่ยนรหัสผ่านใหม่</a
                          >
                        </li>
                      </ul>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <a @click="signOut()" class="font18">ออกจากระบบ</a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="hidden-md">
      <div class="nav-mb" id="nav1">
        <div class="box-nav set-space">
          <div>
            <a href="/home">
              <img
                src="../assets/image/logo_header.png"
                alt
                class="logo-header"
              />
            </a>
            <ul class="name-site">
              <li>
                <el-dropdown class="set-dropdown" placement="bottom-start">
                  <span class="el-dropdown-link">
                    SMID
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="set-dropdown-menu">
                    <el-dropdown-item>
                      <a href="/service">Service</a>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <a href="/research-innovation">Research and Innovation</a>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <a href="/invester-business"
                        >Invester and Business development
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
              <li>
                <router-link to="/blog">Blog</router-link>
              </li>
              <li>
                <router-link to="/about">About Us</router-link>
              </li>
              <li>
                <router-link to="/network">Our NETWORK</router-link>
              </li>
              <li>
                <router-link to="/map">MAP</router-link>
              </li>
            </ul>
          </div>
          <div class>
            <div class="set-height" v-if="user == null">
              <button class="btn btn-color-light mr-15 btn-login">
                <a @click="openModalLogin()">Login</a>
              </button>
              <button class="btn btn-color-dark mr-15 btn-signup">
                <a @click="openModalSignUp()">Sign Up</a>
              </button>
            </div>
            <div v-else class="show-detail-signin">
              <el-dropdown trigger="click" :hide-on-click="false">
                <span class="el-dropdown-link">
                  <img
                    src="../assets/image/user.png"
                    class="profile-user"
                    alt
                    v-if="user.profileImg == undefined"
                  />
                  <img
                    :src="callImage + user._id + '/' + user.profileImg"
                    class="profile-user"
                    alt
                    v-else
                  />
                  <span
                    class="font-active limit-string"
                    v-if="user.name == '' || user.name == undefined"
                    >{{ user.email }}</span
                  >
                  <span class="font-active limit-string" v-else>{{
                    user.name
                  }}</span>
                  <i class="el-icon-arrow-down el-icon--right change-arrow"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="setting-user tab">
                  <el-dropdown-item>
                    <el-collapse>
                      <el-collapse-item
                        title="ตั้งค่าบัญชี"
                        name="1"
                        class="font18"
                      >
                        <ul>
                          <li>
                            <router-link to="/setting/myAccount"
                              >บัญชีของฉัน</router-link
                            >
                          </li>
                          <li>
                            <router-link to="/setting/settingpassword"
                              >เปลี่ยนรหัสผ่านใหม่</router-link
                            >
                          </li>
                        </ul>
                      </el-collapse-item>
                    </el-collapse>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <a @click="signOut()" class="font18">ออกจากระบบ</a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalShow id="modalAll" />
  </div>
</template>
<script>
import modalShow from "@/components/modalShow.vue";
export default {
  data() {
    return {
      countString: "",
      activeName: "setting",
      callImage: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  components: {
    modalShow,
  },
  methods: {
    openModalLogin() {
      this.$store.commit("SET_MODAL", true);
      this.$store.commit("TAB_LOGIN", true);
    },
    openModalSignUp() {
      this.$store.commit("SET_MODAL", true);
      this.$store.commit("TAB_REGIS", true);
    },
    signOut() {
      this.$store.commit("SIGNOUT");
      this.$router.push(`/home`);
    },
  },
};
</script>
<style scope>
.collapse-service {
  border-top: 0 solid #ebeef5 !important;
  border-bottom: 0 solid #ebeef5 !important;
}
.el-collapse-item__wrap {
  border-bottom: 0 solid #ebeef5 !important;
}
.collapse-service .el-collapse-item__content {
  padding-bottom: 10px !important;
  font-size: 18px;
}
.collapse-service .el-collapse-item__header {
  border-bottom: 0 solid #ebeef5 !important;
  height: auto !important;
  line-height: 30px !important;
  font-size: 18px;
  text-decoration: none;
  color: #353448;
  font-weight: 400;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ffffff !important;
}
.el-dropdown-menu__item.is-disabled a {
  color: #bbbbbb !important;
}
.set-dropdown {
  font-size: 20px !important;
  color: #000000 !important;
  cursor: pointer;
}

.el-dropdown-link:focus {
  outline: none;
  box-shadow: none;
}

.set-dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
}
.set-dropdown-menu .el-dropdown-menu__item {
  font-size: 18px !important;
  text-transform: uppercase !important;
}
.set-dropdown-menu li:hover {
  background: #ffffff !important;
}
.set-dropdown-menu li:hover a {
  color: #502abe !important;
}
.set-dropdown-menu .el-dropdown-menu__item a {
  display: block;
  color: #000000;
  text-decoration: none;
}

.profile-user {
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: 50px;
  margin-right: 15px;
  position: absolute;
  left: -60px;
  top: -8px;
}
.show-detail-signin {
  cursor: pointer;
  margin-top: 18px;
}
.font18,
.font18 .el-collapse-item__header {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #000000 !important;
}
.show-detail-signin .btn-color-dark {
  margin-left: 10px;
}
.el-dropdown-menu.setting-user {
  min-width: 200px;
}
.setting-user .el-dropdown-menu__item {
  padding-right: 10px;
}
.setting-user .el-dropdown-menu__item:not(.is-disabled):hover {
  background: #ffffff;
}
.setting-user.tab a {
  display: block;
}
.setting-user.tab a.router-link-active {
  color: #9e76b4;
}
.setting-user ul {
  font-size: 18px;
  list-style: none;
  margin: 0;
  padding-left: 15px;
  color: #606266;
}
.setting-user ul li a {
  color: #606266;
  text-decoration: none;
}
.setting-user ul li:hover a {
  color: #9e76b4;
  text-decoration: none;
}
.setting-user .font18:hover,
.font18 .el-collapse-item__header:hover {
  color: #9e76b4 !important;
}
.setting-user ul li:hover {
  color: #9e76b4;
}
.setting-user .el-collapse-item__content {
  padding-bottom: 0;
}
.ul-smid {
  list-style: none;
  padding-left: 20px;
}
.ul-smid a {
  display: block;
}
.ul-smid .disabled {
  cursor: default;
  pointer-events: none;
}
.ul-smid .disabled a {
  color: #bbb !important;
}
.limit-string {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
    margin-right: 16px;
}
.change-arrow {
  position: absolute;
  top: 0px;
  right: 0;
    margin-top: 10px;
}
@media (max-width: 991.98px) {
  .el-dropdown-menu.setting-user {
    width: 100%;
    left: 0 !important;
  }
}
.height-user {
  padding-top: 10px!important;
}
</style>