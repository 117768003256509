<template>
  <div id="app" v-on:scroll="handleScroll">
    <div
      v-if="user == null || user.role != 50 "
    >

      <Navbar />
      <router-view />
      <Footer />
    </div>
    <div v-else class="role-admin">
      <NavbarAdmin />
      <router-view />
    </div>
  </div>
</template>
<script>
import Footer from "@/components/footer";
import Navbar from "@/components/navbar";
import NavbarAdmin from "@/components/NavbarAdmin";
export default {
  name: "App",
  components: {
    Footer,
    Navbar,
    NavbarAdmin,
  },
  data() {
    return { 
     scrollTop: "" };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
  },
  methods: {
    handleScroll(event) {
      var element = document.getElementById("nav1");
      var element2 = document.getElementById("nav2");
      var loc = window.location.pathname;
      var dir = loc.replace("/", "");
      this.scrollTop = event.srcElement.scrollingElement.scrollTop;
      if (this.user == null || this.user.role != 50) {
        if (this.scrollTop != 0) {
          element.classList.add("fixed");
          element2.classList.add("fixed");
        } else {
          if (dir == "map") {
            element.classList.add("fixed");
          } else {
            element.classList.remove("fixed");
            element2.classList.remove("fixed");
          }
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
};
</script>
